

const host = `${process.env.DEV_BACKEND_URL}/`


module.exports = {
    host: `${process.env.DEV_BACKEND_URL}`,
    register: `${host}customauth/register`,
    verify: `${host}customauth/verifyotp`,
    login: `${host}customauth/login`,
    createAppoinment: `${host}appoinments`,
    appoinmentOtp: `${host}customauth/appoinment`,
    appoinmentVerify: `${host}customauth/appoinmentVerify`,
    updateUser:`${host}customauth/updateuser`,
    updateProfile:`${host}customauth/updateprofile`,
    trackMyJourney:`${host}customauth/trackMyJourney`,
    addEducation:`${host}customauth/addeducation`,
    upload:`${host}upload`,
    updateUserProfile:`${host}users/`,
    updateJourney:`${host}customauth/updatejourney`,
    updateTest:`${host}customauth/updatetest`
}

